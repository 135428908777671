@import '../../variables.scss';

.teamWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 0.5em;

  background-color: #fff;

  padding: 2em 0 5em 0;

  .teamTitle {
    text-align: center;
    font-size: 1.5em;
    font-weight: 400;
    color: #6f6f6f;
    font-family: $secondary-font;
  }
  .teamHeader {
    text-align: center;
    font-size: 3em;

    color: #000;
    font-family: $primary-font;
  }
}

.teamCards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 4em;

  margin-top: 3em;

  .teamCard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    gap: 0.5em;

    width: 300px;
    height: 450px;

    border-radius: 30px;

    padding: 0 0 1em 0;

    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

    background-color: #2e2559;

    .imageContainer {
      width: 100%;
      border-radius: 28px 28px 0 0;

      overflow: hidden;
      .teamCardImage {
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .detailsContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      max-height: 100px;

      padding: 0 1em;

      gap: 0.6em;
      .teamCardName {
        font-size: 1.6em;
        font-weight: 400;
        color: #fff;
        font-family: $secondary-font;
      }
      .teamCardTitle {
        font-size: 1em;
        font-weight: 500;
        color: #cecece;
        font-family: $secondary-font;
      }
    }
  }
}
