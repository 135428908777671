@import '../../variables.scss';

.joinModalWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 0 0 0;
}

.joinModal {
  width: 100%;
  max-width: 50vw;
  background-color: #e2e2e2;

  padding: 2em;
  border-radius: 20px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .completeSubmit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;

    font-family: $secondary-font;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 1px;
    color: $primary-color;
    text-align: center;
  }
}

.joinModalHeader {
  font-family: $primary-font;
  font-size: 1.4em;

  margin-bottom: 1em;

  .joinModalClose {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 1.5rem;
    color: $secondary-color;
    cursor: pointer;
  }
  .joinModalClose svg {
    opacity: 0.6;
  }
  .joinModalClose:hover svg {
    opacity: 1;
    filter: invert(16%) sepia(10%) saturate(3814%) hue-rotate(211deg) brightness(100%) contrast(100%);

    transition: all 0.2s ease-in-out;
  }
}

.joinModalForm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.joinModalForm form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  gap: 2em;
}

.joinModalFormInput {
  width: 100%;
  height: 50px;

  padding: 0 1em;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 1px;

  font-family: 'Outfit', 'serif';
  color: $primary-color;
  text-align: center;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 1em;

  input {
    width: 100%;
    min-width: 200px;
    height: 100%;
    border-radius: 12px;
    border: 2px solid $secondary-color;
    outline: none;
    background-color: #efefef;

    padding: 0.2em 0.5em;

    font-size: 0.8em;
  }
}

.joinModalFormInputGroup {
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.joinModalFormInputGroup .contactInfoFieldSet {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 12px;
  border: 2px solid #222;

  font-family: $secondary-font;
  font-size: 1.5rem;

  legend {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 1px;
    color: $primary-color;
    text-align: center;
    margin-left: -1.2em;
  }

  padding: 2em 1em;

  .joinModalFormInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    font-size: 0.9em;

    height: 100%;
    min-height: 100px;

    font-family: 'Outfit', 'serif';
    color: #222;
    font-weight: 500;
    input {
      flex: 2;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      border: 2px solid $secondary-color;
      outline: none;
      background-color: #efefef;
    }
  }
}

.suggestion {
  font-size: 0.8em;
  font-weight: 500;
  color: $primary-color;
  margin: -1em 0 1em 0;
}

#CVField {
  display: flex;

  width: 100%;
  height: 100%;
  // margin: 0 0 1em 0;

  #CV {
    align-self: center;
    width: 80%;
    height: 100%;
    padding: 0.5em 0;
    text-align-last: center;
    vertical-align: middle;
    margin: auto auto;
  }
}

.joinModalFormSubmit button {
  width: 180px;
  height: 50px;

  padding: 0 1em;

  border-radius: 12px;
  border: 2px solid $primary-color;

  outline: none;

  background-color: $primary-color;

  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;

  font-family: 'Outfit', 'serif';
  color: #fff;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #fff;
    color: $primary-color;
  }
}

@media screen and (max-height: 768px) {
  .joinModal {
    scale: 0.9;
  }

  .suggestion {
    font-size: 1em;
  }
}

@media screen and (max-height: 700px) {
  .joinModal {
    scale: 0.7;
  }

  .suggestion {
    font-size: 1em;
  }
}

@media screen and (max-width: 900px) {
  .joinModal {
    scale: 0.9;
    width: 100%;
    max-width: 90vw;
    background-color: #e2e2e2;

    padding: 2em;
    border-radius: 20px;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .joinModalForm {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 1em;

    padding: 0;

    // .joinModalFormInput {
    //   width: 100%;
    //   height: 50px;

    //   padding: 0 1em;
    //   font-size: 1.4rem;
    //   font-weight: 600;
    //   letter-spacing: 1px;

    //   font-family: 'Outfit', 'serif';
    //   color: $primary-color;
    //   text-align: center;

    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   justify-content: center;

    //   gap: 1em;

    //   input {
    //     width: 100%;
    //     min-width: 200px;
    //     height: 100%;
    //     border-radius: 12px;
    //     border: 2px solid $secondary-color;
    //     outline: none;
    //     background-color: #efefef;

    //     padding: 0.2em 0.5em;

    //     font-size: 0.8em;
    //   }
    // }
    .joinModalFormInputGroup .contactInfoFieldSet {
      width: 100%;
      height: 100%;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 0.5em 1.5em;
    }

    .joinModalFormInputGroup .joinModalFormInput {
      width: 100%;
      height: 100%;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1em 2em;
    }
  }

  .suggestion {
    font-size: 1em;
  }
}
