@import '../../variables.scss';

.servicesWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 1em;

  background-color: #fff;

  padding: 1em 2em;

  .servicesTitle {
    text-align: center;
    font-size: 1.5em;
    font-weight: 400;
    color: #6f6f6f;
    font-family: $secondary-font;

    margin-bottom: -0.5em;
  }
  .servicesHeader {
    text-align: center;
    font-size: 3em;

    color: #000;
    font-family: $primary-font;
  }

  .servicesDesc {
    text-align: center;
    font-size: 1em;
    font-weight: 300;
    color: #6f6f6f;
    font-family: $secondary-font;

    width: 70%;
  }
}

.servicesSectionGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));
  grid-template-rows: repeat(minmax(3, 4), 1fr);
  grid-gap: 1em;

  width: 100%;
  max-width: 1200px;

  padding: 0 1em;

  margin: 1em 3em;

  .serviceElement {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    gap: 1em;

    background-color: #2e2559;
    border-radius: 24px;

    padding: 1.2em 2em;

    .elementTop {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      width: 100%;

      gap: 1em;

      .elementTitle {
        text-align: center;

        font-size: 1.5em;
        font-weight: 400;
        color: #fff;
        font-family: $secondary-font;
      }

      .elementLogo {
        padding: 1.5em;
        border-radius: 10px;

        width: 40px;
        height: 40px;

        background-color: #fff;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 40px;
        }
      }
    }
    .elementDesc {
      text-align: left;

      font-size: 1em;
      font-weight: 300;
      color: #ffffff;
      opacity: 0.5;
      font-family: $secondary-font;
    }
  }
}

.servicesCTA {
  display: none;
  // display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: $primary-color;

  padding: 0.5em 1em;

  border-radius: 10px;

  color: #fff;

  align-self: flex-end;
}

@media screen and (max-width: 900px) {
  .servicesWrapper {
    padding: 1em 1em;
  }

  .servicesTitle {
    font-size: 1.2em;
  }
  .servicesHeader {
    font-size: 2.5em;
  }

  .servicesDesc {
    width: 100%;
  }

  .servicesCTA {
    display: flex;
  }
}
