@import '../../variables.scss';

.contactWrapper {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;

  padding: 1em 0;

  height: 100%;
}

.contact {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 75vh;

  padding: 1em 5em;
  flex-wrap: wrap;
}

.contactTitle {
  font-size: 3.5rem;
  margin-bottom: 20px;
  font-family: 'Gideon Roman', 'serif';
}

.contact-col1 {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: $primary-color;

  padding: 2em 2em;

  border-radius: 22px;

  .col1Title {
    font-size: 2.5em;
    margin-bottom: 10px;
    font-family: 'Gideon Roman', 'serif';
    color: #fff;
  }
  .col1Desc {
    font-size: 1.3em;
    margin-bottom: 20px;
    font-family: 'Outfit', 'serif';
    color: $secondary-color;
  }

  .contactGroups {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    width: 100%;

    padding: 0 1em;

    gap: 3em;

    .contactGroup {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      width: 100%;

      gap: 1em;
    }

    .contactGroupTitle {
      font-size: 1.2rem;

      font-family: 'Outfit', 'serif';
      color: #bbb;
      text-align: left;
    }
    .contactGroupItem {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      gap: 1em;

      margin-left: 0.5em;
      margin-bottom: 0.5em;

      .elemLogo {
        width: 30px;
      }
      .elemData {
        font-size: 1.2rem;

        font-family: 'Outfit', 'serif';
        color: #eee;
        text-align: left;

        text-decoration: none;
      }
    }
  }
}

.contact-col2 {
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: $secondary-color;

  padding: 1em 2em;

  border-radius: 22px;

  gap: 1em;

  .col2Title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-family: 'Gideon Roman', 'serif';
    color: #222;
  }

  .contactForm {
    width: 100%;
  }

  .contactForm form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    width: 100%;

    gap: 2em;

    font-family: 'Outfit', sans-serif;

    font-size: 1.2rem;

    font-family: 'Outfit', 'serif';
    color: #000;
    text-align: left;

    // margin-left: 1em;

    padding: 0 1em;

    #optional {
      font-size: 1em;
      font-family: 'Outfit', 'serif';
      color: #555;

      margin-left: 2em;
    }

    .formGroupDesc {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      width: 100%;

      gap: 1em;

      label {
        font-size: 1.2rem;

        font-family: 'Outfit', 'serif';
        color: #000;
        text-align: left;

        align-self: flex-start;

        // margin-left: 2em;
      }

      #description {
        width: 90%;

        padding: 1em;

        border-radius: 8px;

        outline: none;
        border: none;

        font: 0.9em 'Outfit', 'serif';
        color: #444;

        background-color: #eee;
      }
    }

    .formGroupButton {
      align-self: center;
    }
    .formGroupButton button {
      width: 200px;
      height: 50px;

      padding: 0 1em;

      border-radius: 12px;
      border: 2px solid $primary-color;

      outline: none;

      background-color: $primary-color;

      font-size: 1.2rem;

      font-family: 'Outfit', 'serif';
      color: #fff;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: center;
      cursor: pointer;

      transition: all 0.2s ease-in-out;

      &:hover {
        color: $primary-color;
        background-color: transparent;
      }
      &:active {
        transform: scale(0.95);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      }
    }
  }
  .formGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 2em;
  }
  .formSubGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 1em;

    width: 100%;
    min-width: 500px;
  }
  .formLabel {
    font-size: 1.2rem;

    font-family: 'Outfit', 'serif';
    color: #000;
    text-align: left;
  }
  .formInput {
    min-width: 300px;
    width: 100%;
    height: 40px;

    padding: 0 1em;

    border-radius: 8px;

    outline: none;
    border: none;

    font: 0.9em 'Outfit', 'serif';
    color: #444;

    background-color: #eee;
  }
  .formSelect {
    min-width: 300px;
    width: 100%;
    height: 40px;

    padding: 0 1em;

    border-radius: 8px;

    outline: none;
    border: none;

    font: 0.9em 'Outfit', 'serif';
    color: #444;

    background-color: #eee;
  }
}

#documents {
  width: 70%;
  input {
    align-self: center;
    width: 100%;
    min-width: 300px;
    height: 100%;

    padding: 0.5em 1em;
    text-align-last: center;
    vertical-align: middle;
    margin: auto auto;
    background-color: #eee;
    border-radius: 8px;
  }
}

.separator {
  width: 2px;
  height: 400px;
  border-radius: 10px;

  background-color: $primary-color;

  margin: 0 30px;

  outline: none;
  border: none;
}

.completeText {
  width: 80vw;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 2em;
  font-family: 'Outfit', 'serif';
  color: #fff;
  text-align: center;
  font-weight: 500;
  letter-spacing: 2px;
  text-align: center;
}

.loaderWrapper {
  width: 80vw;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.suggestion {
  margin-bottom: -0.5em;
}

@media screen and (max-width: 1800px) {
  .contact {
    gap: 2em;
    height: 100%;
  }
  .contact-col1 {
    flex: 1;
    .contactGroups {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 2em;
      .contactGroup {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2em;
      }
    }
  }
  .contact-col2 {
    flex: 1;

    margin-bottom: 1em;
  }

  .separator {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .contact {
    // flex-direction: column;
    gap: 2em;
  }
  .contact-col1 {
    flex: 1;
    max-width: 50%;
    .contactGroups {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2em;
      .contactGroup {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2em;
      }
    }
  }
  .contact-col2 {
    flex: 1;
  }
  .formGroup {
    flex-wrap: wrap;
  }

  .separator {
    display: none;
  }

  .completeText {
    width: 90vw;
    height: 80vh;
  }

  .loaderWrapper {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 900px) {
  .contact-col1 {
    flex: 1;
    max-width: 100%;
    padding: 1em 3em;
    .contactGroups {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2em;
      .contactGroup {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2em;
      }
    }
  }

  .contact-col2 {
    flex: 1;
    padding: 1em 0em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // max-width: 100%;
    .contactForm {
      width: 100%;
      max-width: 100%;
      padding: 1em 0em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .contactForm form {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1em 0.5em;
      width: fit-content;
      // max-width: 100%;
    }
    #documents {
      width: 100%;
      #document {
        width: fit-content;
      }
    }
    .formInput {
      width: 90%;
      padding: 0 0.2em;
    }
    .formGroup {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 1em;
      .formSubGroup {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1em;

        min-width: 90%;
      }
      .formSelect {
        min-width: 300px;
        width: 100%;
        height: 40px;

        padding: 0 1em;

        border-radius: 8px;

        outline: none;
        border: none;

        font: 0.9em 'Outfit', 'serif';
        color: #444;

        background-color: #eee;
      }
    }
  }
  .completeText {
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
  }

  .loaderWrapper {
    width: 100%;
    height: 100%;
  }
}
