@import '../../_variables.scss';

.navbarWrapper {
  // position: fixed;
  display: flex;

  width: 100%;

  box-shadow: 1px 1px 5px 1px #00000011;
  background-color: #ffffffcc;

  user-select: none;

  z-index: 1000;
}

.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  padding: 0.3em 1em;
}

.navbarLogo {
  height: 50px;
  transition: all 0.3s ease-in-out;
  img {
    width: 140px;
  }
  &:hover {
    cursor: pointer;

    scale: 1.04;
  }
}

.navbarLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  font-size: 1.1em;
  font-family: $secondary-font;
  font-weight: 300;

  transition: all 0.3s ease-in-out;

  justify-self: flex-end;

  transform: translateX(2em);

  text-decoration: none;

  #navLink {
    text-decoration: none;
    color: black;
    &:hover {
      cursor: pointer;
      font-weight: 500;
      text-shadow: 1px 1px 2px 1px #00000011;
    }
  }
}

.navbarCTA {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 2em;
}

.CTA {
  padding: 0.5em 1.5em;
  border-radius: 10px;

  font-family: $secondary-font;
  font-weight: 500;

  transition: all 0.2s ease-in-out;

  cursor: pointer;
}

#contact {
  color: white;
  background-color: $primary-color;
  border: 2px solid $primary-color;
  text-decoration: none;
  NavLink {
    text-decoration: none;
  }
  &:hover {
    color: $primary-color;
    background-color: transparent;
  }

  &:active {
    scale: 0.94;
    box-shadow: 0 0 4px $primary-color inset;
  }
}

#join {
  color: white;
  background-color: $secondary-color;
  border: 2px solid $secondary-color;
  &:hover {
    color: $secondary-color;
    background-color: transparent;
  }

  &:active {
    scale: 0.94;
    box-shadow: 0 0 4px $secondary-color inset;
  }
}

.mobileSideNav {
  display: none;
}

.mobileSideNavBtn {
  display: none;
}

@media screen and (max-width: 900px) {
  .navbarLinks {
    display: none;
  }
  .navbarLogo {
    scale: 0.8;
    &:hover {
      scale: 0.85;
    }
  }
  .CTA {
    scale: 0.8;
  }
  .mobileSideNavBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    font-size: 1.5em;
    font-weight: 600;
    color: #000000cc;
    &:hover {
      cursor: pointer;
      color: $secondary-color;
    }
  }
  #contact {
    display: none;
  }
  #closeNav {
    position: absolute;
    top: 20px;
    right: 20px;

    color: #ffffff;
    font-size: 2em;
    font-weight: 600;
    justify-self: flex-start;
    transition: all 0.3s ease-in-out;
    &:hover {
      cursor: pointer;
      scale: 1.1;
      color: $secondary-color;
    }
  }
  .mobileSideNav {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1em;
    width: 100%;
    height: 100%;

    padding: 1em 0;
    background-color: #2c2c2cee;
    box-shadow: 1px 1px 5px 1px #00000011;
    z-index: 1000;

    overflow-x: hidden;

    .mobileSideNavLinks {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1em;
      // width: 100%;
      // height: 100%;

      #navLink {
        text-decoration: none;
        color: #ffffff;
        font-weight: 600;
        font-family: 'Outfit', sans-serif;
        font-size: 2em;
        transition: all 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          scale: 1.1;
          color: $secondary-color;
        }
      }
    }
  }
}

.hidden {
  display: none;
}
