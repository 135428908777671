@import '../../variables.scss';

.reviewWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f5f5f4;
  padding: 2em 0;
}

.review {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //   width: 100%;
  //   height: 100%;
  gap: 0.4em;

  //   padding: 0 2em;
}

.reviewTitle {
  text-align: center;
  font-size: 1.5em;
  font-weight: 400;
  color: #6f6f6f;
  font-family: $secondary-font;
}

.reviewHeader {
  font-size: 3em;
  font-family: $primary-font;
}

.reviewSubtext {
  text-align: center;
  font-size: 1em;
  font-weight: 300;
  color: #6f6f6f;
  font-family: 'Outfit';
  width: 70%;
}

.reviewSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  gap: 2em;
  margin: 3em 0 1em 0;
  padding: 2em 2em;
  background-color: $primary-color;
}

.reviewCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 20vw;
  min-height: 30vh;
  gap: 0.5em;
  padding: 1em;
  border-radius: 0.5em;
  background-color: #ffffff;
  box-shadow: 0 0 0.5em 0.1em #0000001a;

  user-select: none;
}

// .reviewCardStars {
//   position: absolute;
//   top: 10px;
//   left: 50;
//   margin-left: auto;
//   margin-right: auto;
//   font-size: 1.5em;
//   //   color: #f5f5f4;
// }

.reviewCardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-self: center;
  gap: 0.5em;
  width: 90%;
  height: 100%;

  margin-top: 1em;

  font-size: 1.2em;
  font-weight: 400;
  color: #2d2d2d;
  font-family: $secondary-font;
}

.reviewCardName {
  justify-self: flex-end;
  align-self: flex-end;
  font-size: 1.25em;
  font-weight: 400;
  color: $primary-color;
  font-family: $secondary-font;
  text-decoration: underline;

  margin-top: 1em;
}

@media screen and (max-width: 900px) {
  .reviewSection {
    flex-direction: column;
    gap: 1em;
  }
}
