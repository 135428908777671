@import '../../variables.scss';

.counterWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  width: 100%;
  min-height: 40vh;
  height: 90%;

  background-color: $tertiary-color;

  border-radius: 12em 0 0 0;

  padding: 2em 0;

  gap: 2em;

  background-image: url('../../assets/counterback.svg');

  background-repeat: no-repeat;
  background-position: right;
}

.counterTitle {
  font-size: 4em;
  font-weight: 500;
  font-family: $primary-font;
}

.counter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  gap: 1em;

  flex-wrap: wrap;
}

.counterItem {
  min-width: 380px;
  height: 180px;

  display: flex;
  align-items: center;

  padding: 0 1.2em;

  background-color: #f4f4f4;
  border-radius: 130px;
  .counterContents {
    width: 90%;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.counterDetail {
  padding: auto;

  font-family: $secondary-font;
  font-size: 1.6em;

  letter-spacing: 1px;
}
.counterItemLogo {
  margin: 0 0 0 -1em;
  svg {
    width: 70%;
  }
}

@media screen and (max-width: 1550px) {
  .counterTitle {
    font-size: 3.2em;
  }

  .counterItem {
    min-width: 300px;
    height: 150px;
  }

  .counterDetail {
    font-size: 1.2em;
  }

  .counterItemLogo {
    margin: 0 0 0 -0.5em;
    svg {
      width: 60%;
    }
  }
}

@media screen and (max-width: 900px) {
  .counterWrapper {
    border-radius: 0;
    background-image: none;
  }

  .counterTitle {
    font-size: 2.4em;
  }

  .counterItem {
    // min-width: 250px;
    // height: 120px;
    scale: 0.9;
  }

  // .counterDetail {
  //   font-size: 1em;
  // }

  // .counterItemLogo {
  //   margin: 0 0 0 -0.5em;
  //   svg {
  //     width: 50%;
  //   }
  // }
}
