@import '../../variables.scss';

.aboutWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 65vh;
  width: 100%;

  background-color: #827c9b;

  gap: 1em;
}

.about {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100%;
  height: 100%;

  padding: 0 2em;

  gap: 1em;
}

.aboutImage {
  display: flex;
  align-items: center;
  justify-content: center;

  object-fit: cover;

  img {
    width: 65%;
    height: 55vh;
    object-fit: cover;
    border-radius: 15px;
  }
}

.about-col2 {
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  //   margin: 0 0 0 2em;

  padding: 3em 0;
  gap: 1em;

  background-image: url('../../assets/aboutback.svg');
  background-size: 85%;
  background-repeat: no-repeat;
  background-position: center;
  text-align: left;

  .aboutTitle {
    font-size: 1.5em;
    font-weight: 400;
    color: #ffffffcc;

    font-family: $secondary-font;
    text-align: left;
  }
  .aboutHeader {
    font-size: 3em;
    font-weight: 400;
    color: #000000;

    font-family: $primary-font;
    text-align: left;

    .highlightText {
      color: $primary-color;
    }
  }

  .aboutDesc {
    width: 65%;

    font-size: 1.1em;
    font-weight: 400;
    color: #d9d9d9;

    padding: 0 0.5em;

    font-family: $secondary-font;
    text-align: left;
  }
}

.aboutCTA {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;

  margin: 3em 4em 0 0;

  align-self: flex-end;

  color: #ffffff;
  background-color: $primary-color;
  border-radius: 10px;
  padding: 0.7em 1.2em;

  text-decoration: none;

  font-size: 1.5em;
  font-weight: 500;
  font-family: $secondary-font;

  transition: all 0.4s ease-in-out;

  border: 2px solid $primary-color;

  cursor: pointer;

  &:hover {
    color: $primary-color;
    background-color: transparent;
  }
}

@media screen and (max-width: 900px) {
  #breaker {
    display: none;
  }
  .aboutWrapper {
    flex-direction: column;
    gap: 1em;

    padding: 1em 0;
  }

  .about {
    flex-direction: column;
    gap: 2em;
  }

  .aboutImage {
    margin: 0 0 -2em 0;
    img {
      width: 100%;
      height: 40vh;
    }
  }

  .about-col2 {
    width: 100%;
    padding: 3em 0;

    background-image: url('../../assets/aboutback.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    .aboutTitle {
      align-self: center;
    }
    .aboutHeader {
      font-size: 3em;
      text-align: center;
    }
    .aboutDesc {
      width: 100%;
    }
  }

  .aboutCTA {
    align-self: center;
    margin: 3em 0 0 0;
  }
}
