@import '../../variables.scss';

.footerWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  background-color: #2e2559;
  padding: 1em 5em;

  color: #efefef;
}

.footer-col1 {
  //   flex: 4;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 100%;

  gap: 1em;

  margin-right: auto;
  padding-left: 5em;

  .logo {
    opacity: 90%;
  }

  .socialLinks {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    gap: 2em;

    .social {
      width: 35px;
      transition: all 0.5 ease-in-out;
      cursor: pointer;
      &:hover {
        scale: 1.05;
      }
    }
  }
}
.footer-col2 {
  height: 100%;

  width: 20%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  gap: 1em;

  .col2-elem {
    display: flex;
    align-items: justify;
    justify-content: justify;
    flex-direction: row;

    gap: 1em;

    .elemLogo {
      width: 30px;
    }
  }
  .elemLogo {
    width: 30px;
  }

  .header {
    font-size: 1.6em;
    font-weight: 400;
    color: #fff;
    font-family: $secondary-font;

    margin: 0 0 0 2em;
  }
  .elemData {
    font-size: 1.1em;
    font-weight: 300;
    color: #efefef;
    font-family: $secondary-font;

    text-decoration: none;
    text-align: left;
  }
}
.footer-col3 {
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  margin-left: 2em;

  gap: 1em;

  .col3-elem {
    font-size: 1.1em;
    font-weight: 300;
    color: #efefef;
    font-family: $secondary-font;

    text-decoration: none;
  }
  .header {
    font-size: 1.6em;
    font-weight: 400;
    color: #fff;
    font-family: $secondary-font;
  }
}

@media screen and (max-width: 900px) {
  .footerWrapper {
    flex-direction: column;
    justify-content: center;
    padding: 1em 2em;

    gap: 2em;
  }
  .footer-col1 {
    margin-right: 0;
    padding-left: 0;
    margin-top: 2em;
    .logo {
      display: none;
    }
  }
  .footer-col2 {
    width: 100%;
    margin: 0 0 1em 0;
    .header {
      margin: 0;
    }
  }
  .footer-col3 {
    width: 100%;
    margin: 0 0 1em 0;
  }
}
