@import '../../_variables.scss';

.landingWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 65vh;
  // max-height: 100px;

  // padding-top: 1.5em;
}

.landingWrapperMobile {
  display: none;
}

.landing-col1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  padding: 3em 0 0 10em;
  gap: 2em;
}

.landingLogo {
  img {
    width: 360px;
  }
}

.landingTitle {
  font-size: 4.2em;
  font-weight: 400;
  color: #000000;

  font-family: $primary-font;
  text-align: left;
}

.landingDesc {
  width: 80%;

  font-size: 1.3em;
  font-weight: 400;
  color: #333;

  font-family: $secondary-font;
  text-align: left;
  .highlighted {
    color: $primary-color;
    font-weight: 600;
  }
}

.landingCTA {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;

  color: #ffffff;
  background-color: $primary-color;
  border-radius: 10px;
  padding: 0.7em 1.2em;

  font-size: 1.5em;
  font-weight: 500;
  font-family: $secondary-font;

  text-decoration: none;

  transition: all 0.4s ease-in-out;

  border: 2px solid $primary-color;

  cursor: pointer;

  &:hover {
    color: $primary-color;
    background-color: transparent;
  }

  &:active {
    box-shadow: 0 0 4px $primary-color inset;
  }
}

.landing-col2 {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}
.landingImage {
  position: relative;
  width: 100%;

  img {
    position: absolute;
    bottom: 0;
    right: 1em;
    width: 100%;
  }
}

@media screen and (max-width: 1550px) {
  .landingTitle {
    font-size: 3.2em;
  }

  .landingDesc {
    width: 90%;

    font-size: 1.2em;
  }

  .landingCTA {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 900px) {
  .landingWrapper {
    display: none;
  }
  .landingWrapperMobile {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    // min-height: 80vh;
    // flex-direction: column;
    // gap: 2em;
    // padding: 0 1em;

    .landing-col1 {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      padding: 3em 1em;
      background-image: url('../../assets/mobilelanding.svg');
      // gap: 2em;
    }

    .landingLogo {
      img {
        width: 160px;
      }
    }

    .landingTitle {
      font-size: 2.5em;
      // font-weight: 400;
      // color: #000000;

      // font-family: $primary-font;
      // text-align: left;
    }

    .landingDesc {
      width: 80%;
      font-size: 1em;
      // font-weight: 400;
      // color: #333;

      // font-family: $secondary-font;
      // text-align: left;
      .highlighted {
        color: $primary-color;
        font-weight: 600;
      }
    }

    .landingCTA {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // gap: 1em;

      // color: #ffffff;
      // background-color: $primary-color;
      // border-radius: 10px;
      // padding: 0.7em 1.2em;

      // font-size: 1.5em;
      // font-weight: 500;
      // font-family: $secondary-font;

      // text-decoration: none;

      // transition: all 0.4s ease-in-out;

      // border: 2px solid $primary-color;

      // cursor: pointer;
      scale: 0.9;

      &:hover {
        color: $primary-color;
        background-color: transparent;
      }

      &:active {
        box-shadow: 0 0 4px $primary-color inset;
      }
    }
    // .landingImage {
    //   position: relative;
    //   width: 100%;
    //   z-index: -100;
    //   img {
    //     -webkit-transform: scaleX(-1);
    //     transform: scaleX(-1);
    //     position: absolute;
    //     bottom: 0;
    //     right: 1em;
    //     width: 100%;
    //   }
    // }
  }
}
